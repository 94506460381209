import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import issue from "./issue/Index"
import notification from "./notification/Index"
import cart from "./cart/Index"

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  issue: issue,
  notification: notification,
  cart: cart
})

export default rootReducer
