import React from "react"
import * as Icon from "react-feather"

const navigationConfig = [
  {
    id: "scoreDash",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["RoleSuperUser", "RoleCoreBasicDashboard", "RoleEnhancedBasicDashboard", "RoleAdvanced", "RoleAdvancedRewards", "RoleEnhancedBasicDashboardRewards", "RoleCoreAdmin", "RoleEnhancedAdmin", "RoleEnhancedAdminRewards", "RoleAdvancedRiskProfile", "RoleAdvancedRiskProfileRewards"],
    navLink: "/"
  },
  {
    id: "rewardsDash",
    title: "Rewards",
    type: "item",
    icon: <Icon.Award size={20} />,
    permissions: ["RoleSuperUser", "RoleAdvancedRewards", "RoleEnhancedBasicRewards", "RoleEnhancedBasicDashboardRewards", "RoleEnhancedAdminRewards", "RoleAdvancedRiskProfileRewards"],
    navLink: "/rewards"
  },
  {
    id: "my-culture",
    title: "My Culture",
    type: "item",
    icon: <Icon.ThumbsUp size={20} />,
    permissions: ["RoleSuperUser", "RoleEnhancedBasic", "RoleEnhancedBasicDashboard", "RoleAdvanced", "RoleAdvancedRewards", "RoleEnhancedBasicRewards", "RoleEnhancedBasicDashboardRewards", "RoleEnhancedAdmin", "RoleEnhancedAdminRewards", "RoleAdvancedRiskProfile", "RoleAdvancedRiskProfileRewards"],
    navLink: "/my-culture"
  },
  {
    id: "expressions",
    title: "Expressions",
    type: "item",
    icon: <Icon.Smile size={20} />,
    permissions: ["RoleSuperUser", "RoleEnhancedBasic", "RoleEnhancedBasicDashboard", "RoleAdvanced", "RoleAdvancedRewards", "RoleEnhancedBasicRewards", "RoleEnhancedBasicDashboardRewards", "RoleEnhancedAdmin", "RoleEnhancedAdminRewards", "RoleAdvancedRiskProfile", "RoleAdvancedRiskProfileRewards"],
    navLink: "/expressions"
  },
  {
    id: "survey",
    title: "Survey",
    type: "item",
    icon: <Icon.CheckSquare size={20} />,
    permissions: ["RoleSuperUser", "RoleCoreBasic", "RoleCoreBasicDashboard", "RoleEnhancedBasic", "RoleEnhancedBasicDashboard", "RoleAdvanced", "RoleAdvancedRewards", "RoleEnhancedBasicRewards", "RoleEnhancedBasicDashboardRewards", "RoleCoreAdmin", "RoleEnhancedAdmin", "RoleEnhancedAdminRewards", "RoleAdvancedRiskProfile", "RoleAdvancedRiskProfileRewards"],
    navLink: "/survey"
  },
  {
    id: "alert",
    title: "Score Alerts",
    type: "item",
    icon: <Icon.AlertCircle size={20} />,
    permissions: ["RoleSuperUser" , "RoleAdvanced", "RoleAdvancedRewards", "RoleAdvancedRiskProfile" , "RoleAdvancedRiskProfileRewards"],
    navLink: "/score-alert"
  },
  {
    id: "rule-builder",
    title: "Rule Builder",
    type: "item",
    icon: <Icon.Sliders size={20} />,
    permissions: ["RoleSuperUser", "RoleAdvanced", "RoleAdvancedRewards", "RoleAdvancedRiskProfile", "RoleAdvancedRiskProfileRewards"],
    navLink: "/rule-builder"
  },
  {
    id: "survey-manager",
    title: "Survey Manager",
    type: "item",
    icon: <Icon.UserCheck size={20} />,
    permissions: ["RoleSuperUser", "RoleAdvanced", "RoleAdvancedRewards", "RoleCoreAdmin", "RoleEnhancedAdmin", "RoleEnhancedAdminRewards", "RoleAdvancedRiskProfile", "RoleAdvancedRiskProfileRewards"],
    navLink: "/survey-manager"
  },
  {
    id: "reward-campaigns",
    title: "Reward Campaigns",
    type: "item",
    icon: <Icon.Award size={20} />,
    permissions: ["RoleSuperUser", "RoleAdvancedRewards", "RoleEnhancedAdminRewards", "RoleAdvancedRiskProfileRewards"],
    navLink: "/reward-campaigns"
  },
  {
    id: "cultural-types",
    title: "Culture Admin",
    type: "item",
    icon: <Icon.ThumbsUp size={20} />,
    permissions: ["RoleSuperUser"],
    navLink: "/culture-admin"
  },
  {
    id: "expression-admin",
    title: "Expression Admin",
    type: "item",
    icon: <Icon.Smile size={20} />,
    permissions: ["RoleSuperUser"],
    navLink: "/expression-admin"
  },
  {
    id: "value-admin",
    title: "Value Admin",
    type: "item",
    icon: <Icon.Anchor size={20} />,
    permissions: ["RoleSuperUser"],
    navLink: "/value-admin"
  },
  {
    id: "rule-value-admin",
    title: "Rule Value Admin",
    type: "item",
    icon: <Icon.Cpu size={20} />,
    permissions: ["RoleSuperUser"],
    navLink: "/rule-value-admin"
  },
  {
    id: "risk-admin",
    title: "Risk Admin",
    type: "item",
    icon: <Icon.AlertCircle size={20} />,
    permissions: ["RoleSuperUser"],
    navLink: "/risk-admin"
  },
  {
    id: "remedy-admin",
    title: "Remedy Admin",
    type: "item",
    icon: <Icon.Shield size={20} />,
    permissions: ["RoleSuperUser"],
    navLink: "/remedy-admin"
  },
  {
    id: "cultural-factor-admin",
    title: "Cultural Factor Admin",
    type: "item",
    icon: <Icon.Activity size={20} />,
    permissions: ["RoleSuperUser"],
    navLink: "/cultural-factor-admin"
  }
]

export default navigationConfig
