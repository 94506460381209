const notificationReducer = (state = { notifications: [] }, action) => {
  switch (action.type) {
    case "NOTIFICATION": {
      return { ...state, notifications: action.notifications }
    }
    default: {
      return state
    }
  }
}

export default notificationReducer
