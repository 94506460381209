const issueReducer = (state = { selectedFilters: [], currentUser: '', selectedIssue: '0', userRole: '' }, action) => {
  switch (action.type) {
    case "SET_FILTERS": {
      return { ...state, selectedFilters: action.selectedFilters }
    }
    case "CURRENT_USER": {
      return { ...state, currentUser: action.currentUser }
    }
    case "SELECTED_ISSUE": {
      return { ...state, selectedIssue: action.selectedIssue }
    }
    case "USER_ROLE": {
      return { ...state, userRole: action.userRole }
    }
    default: {
      return state
    }
  }
}

export default issueReducer
