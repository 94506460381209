module.exports = Object.freeze({
  FINAL_AUTHORITY_ROLE: '92111a6d-0d98-4744-88fb-d3ed3cc6f4f8',
  REVIEWER_ROLE: '125ee61d-e2e8-4d53-8f5b-cb67bd3315e4',
  INVESTIGATOR_ROLE: 'ebcfc80a-2a1a-44e5-86a1-02cb6bd5ca53',
  OPEN_STATE: '78e15007-1970-483d-b95b-ad8a0ef0ab11',
  REVIEW_STATE: 'a36d02a4-bd10-40da-8cfe-d76098e5d0a1',
  ASSIGNED_STATE: 'ea0c1fc8-1023-4299-bada-667688a323b5',
  COMPLETED_STATE: '4a336bb5-e638-4732-94b4-d5d60d8cc064',
  CLOSED_STATE: 'a7d056c9-964d-4d81-b14d-a2084c70fa8f',
  CANCELLED_STATE: '282f6639-4f42-4088-bac5-7dfc6efbb5f7',
  SURVEY_IN_PROGRESS_STATE: '243c14bb-7127-47fc-aeb5-8cbe84e373e5',
  SURVEY_BUILDING_STATE: 'cc7b5e6b-6201-4b70-b7b6-3f4fc0179319',
  SURVEY_COMPLETED_STATE: '74703596-d6ac-48de-baa2-818e80d998a9',
  ALL_REGION: '347c5912-b95b-41a2-a28e-aacaaf93e065',
  ALL_DEPARTMENT: '037d3c1c-4126-46c4-b0d5-c30d1b38c11e',
  NOTIFICATION_SURVEY: '8a66ef91-17cb-4892-ad82-a7d8677ce641',
  NOTIFICATION_ALERT: '5723bfc9-b477-4eef-9c98-67580193bc4d',
  NOTIFICATION_SCORE_ALERT: '3d32563d-9e8c-43ee-ab71-f2c33783c2d5',
  NOTIFICATION_MESSAGE: 'cc0b9b08-ab8e-4fbb-a437-eb8d7f9f64a9',
  NOTIFICATION_REWARD_RECEIVED: 'dc0b23d4-6b9e-43df-b9e5-fcbf9417e6a3',
  NOTIFICATION_REWARD_REDEEM: '3b8602d3-2f6a-4acb-9a0c-7e704b6e8689',
  RULE_CONDITION_TYPE_EVENT: '61326a0c-32a7-4f14-8072-4d5b3440bea3',
  RULE_CONDITION_TYPE_EXPRESSION_EVENT: 'ed2f3535-7b18-4084-936d-8aa18951ba97',
  RULE_CONDITION_TYPE_SCORE: '36f722c5-bde9-4a2d-a9ff-52191bf2a21b',
  RULE_CONDITION_APP_DASHBOARD: '9b9dc4bd-e31c-4d22-ae78-eabe269ec5a8',
  RULE_CONDITION_APP_CULTURE_MANAGER: '9b9dc4bd-e31c-4d22-ae78-eabe269ec5a8',
  RULE_ACTION_TYPE_RAISE_ALERT: 'd1807851-c624-4ae6-ab8d-170391d5f2a5',
  RULE_ACTION_TYPE_SURVEY_MANAGER: '05a0d1bf-3c41-487b-a0df-b76f1cbb86ef',
  RULE_ACTION_TYPE_SEND_FILE: 'b3b9c768-f48a-448c-929c-c068bba36521',
  RULE_ACTION_TYPE_SEND_MESSAGE: '208e81e6-6e10-47b2-8ab4-2e2ed00c51ae'
});