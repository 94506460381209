import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "scoreDash",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor", "DashboardFull", "DashboardSummary"],
    navLink: "/"
  },
  {
    id: "rewardsDash",
    title: "Rewards",
    type: "item",
    icon: <Icon.Award size={20}  />,
    permissions: ["admin", "everyone"],
    navLink: "/rewards"
  },
  {
    id: "my-culture",
    title: "My Culture",
    type: "item",
    icon: <Icon.ThumbsUp size={20} />,
    permissions: ["admin", "editor", "everyone"],
    navLink: "/my-culture"
  },
  {
    id: "expressions",
    title: "Expressions",
    type: "item",
    icon: <Icon.Smile size={20} />,
    permissions: ["admin", "editor", "everyone"],
    navLink: "/expressions"
  },
  {
    id: "survey",
    title: "Survey",
    type: "item",
    icon: <Icon.CheckSquare size={20} />,
    permissions: ["admin", "editor", "everyone"],
    navLink: "/survey"
  },
  {
    id: "alert",
    title: "Score Alerts",
    type: "item",
    icon: <Icon.AlertCircle size={20} />,
    permissions: ["admin", "editor", "ScoreAlerts"],
    navLink: "/score-alert"
  },
  {
    id: "rule-builder",
    title: "Rule Builder",
    type: "item",
    icon: <Icon.Sliders size={20} />,
    permissions: ["admin", "editor", "EventRules"],
    navLink: "/rule-builder"
  },
  {
    id: "survey-manager",
    title: "Survey Manager",
    type: "item",
    icon: <Icon.UserCheck size={20} />,
    permissions: ["admin", "editor", "SurveyManager"],
    navLink: "/survey-manager"
  },
  {
    id: "reward-campaigns",
    title: "Reward Campaigns",
    type: "item",
    icon: <Icon.Award size={20} />,
    permissions: ["admin", "editor", "RewardManager"],
    navLink: "/reward-campaigns"
  },
  {
    id: "cultural-types",
    title: "Culture Admin",
    type: "item",
    icon: <Icon.ThumbsUp size={20} />,
    permissions: ["admin", "editor", "SystemAdmin"],
    navLink: "/culture-admin"
  },
  {
    id: "expression-admin",
    title: "Expression Admin",
    type: "item",
    icon: <Icon.Smile size={20} />,
    permissions: ["admin", "editor", "SystemAdmin"],
    navLink: "/expression-admin"
  },
  {
    id: "value-admin",
    title: "Value Admin",
    type: "item",
    icon: <Icon.Anchor size={20} />,
    permissions: ["admin", "editor", "SystemAdmin"],
    navLink: "/value-admin"
  },
  {
    id: "rule-value-admin",
    title: "Rule Value Admin",
    type: "item",
    icon: <Icon.Cpu size={20} />,
    permissions: ["admin", "editor", "SystemAdmin"],
    navLink: "/rule-value-admin"
  },
  {
    id: "risk-admin",
    title: "Risk Admin",
    type: "item",
    icon: <Icon.AlertCircle size={20} />,
    permissions: ["admin", "editor", "SystemAdmin"],
    navLink: "/risk-admin"
  },
  {
    id: "remedy-admin",
    title: "Remedy Admin",
    type: "item",
    icon: <Icon.Shield size={20} />,
    permissions: ["admin", "editor", "SystemAdmin"],
    navLink: "/remedy-admin"
  },
  {
    id: "cultural-factor-admin",
    title: "Cultural Factor Admin",
    type: "item",
    icon: <Icon.Activity size={20} />,
    permissions: ["admin", "editor", "SystemAdmin"],
    navLink: "/cultural-factor-admin"
  }
]

export default horizontalMenuConfig
