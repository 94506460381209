import React from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
  Spinner
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import axios from "axios"
import * as Icon from "react-feather"
import { history } from "../../../history"
import { AmplifySignOut } from '@aws-amplify/ui-react';
import Constants from '../../../constants';
import { API, Auth } from 'aws-amplify';
import { connect } from "react-redux"
import { setNotifications } from "../../../redux/actions/notification/Index";
import { changeMode } from "../../../redux/actions/customizer/index"

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

const UpdateThemePref = (value, props) => {
  props.changeMode(value)
  localStorage.setItem('theme', value);

  Auth.currentSession()
    .then(info => {

      const init = {
        body: {
          tk: info.getAccessToken().getJwtToken(),
          th: value
        }
      };

      const p1 = new Promise((resolve, reject) => {
        API.put('CultureApi', '/user-prefs', init)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          });
      });

      Promise.all([p1])
        .then(values => {

        })
        .catch(error => {
          console.log(error);
        });

    })
    .catch(error => {
      console.log(error);
    });

};

const UserDropdown = props => {
  return (
    <DropdownMenu right>
      <DropdownItem tag="a" href="#">
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Edit Profile</span>
      </DropdownItem>
      {/* <DropdownItem tag="a" href="#">
        <Icon.Mail size={14} className="mr-50" />
        <span className="align-middle">My Inbox</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.CheckSquare size={14} className="mr-50" />
        <span className="align-middle">Tasks</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.MessageSquare size={14} className="mr-50" />
        <span className="align-middle">Chats</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.Heart size={14} className="mr-50" />
        <span className="align-middle">WishList</span>
      </DropdownItem> */}
      {props.app.customizer.theme === 'dark' &&
        <DropdownItem tag="a" href="#" onClick={() => UpdateThemePref("light", props)}>
          <Icon.CheckSquare size={14} className="mr-50" />
          <span className="align-middle">Light</span>
        </DropdownItem>
      }

      {/* <DropdownItem tag="a" href="#" onClick={e => props.handleThemeChange('semi-dark')}>
        <Icon.CheckSquare size={14} className="mr-50" />
        <span className="align-middle">Semi-Dark</span>
      </DropdownItem> */}

      {props.app.customizer.theme === 'light' &&
        <DropdownItem tag="a" href="#" onClick={() => UpdateThemePref("dark", props)}>
          <Icon.CheckSquare size={14} className="mr-50" />
          <span className="align-middle">Dark</span>
        </DropdownItem>
      }

      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => history.push("/pages/login")}
      >
        {/* <Icon.Power size={14} className="mr-50" /> */}
        {/* <span className="align-middle">Log Out</span> */}
        <div className="ml-n2">
          <AmplifySignOut />
        </div>

      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
    items: [],
    userName: ''
  }

  componentDidMount() {
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult })
    })

    const theme = localStorage.getItem('theme');

    if(theme !== undefined)
      this.props.changeMode(theme);

    Auth.currentSession()
      .then(info => {

        const init = {
          body: {
            tk: info.getAccessToken().getJwtToken()
          }
        };

        const p3 = new Promise((resolve, reject) => {
          API.post('CultureApi', '/user-prefs', init)
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error)
            });
        });

        Promise.all([p3])
          .then(values => {

            if (!isEmpty(values[0])) {
              this.props.changeMode(values[0].th);
              localStorage.setItem('theme', values[0].th);
            }else{
              localStorage.setItem('theme', 'light');
            }

            const p1 = new Promise((resolve, reject) => {
              API.post('CultureApi', '/notifications', init)
                .then(response => {
                  resolve(response);
                })
                .catch(error => {
                  reject(error)
                });
            });

            const p2 = new Promise((resolve, reject) => {
              API.post('CultureApi', '/info-users', init)
                .then(response => {
                  resolve(response);
                })
                .catch(error => {
                  reject(error)
                });
            });

            Promise.all([p1, p2])
              .then(values => {

                this.setState({
                  isError: false,
                  isLoading: false,
                  items: values[0],
                  userName: 'Hello, ' + values[1].name
                });
              })
              .catch(error => {
                console.log(error);
                this.setState({
                  isLoading: false,
                  isError: true
                });
              });
          });

      })
      .catch(error => {
        console.log(error);
        this.setState({
          isLoading: false,
          isError: true
        });
      });
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }

  handleNotificationClick = (sk) => {

    this.setState({
      isError: false,
      isLoading: true
    });

    Auth.currentSession()
      .then(info => {

        const init = {
          body: {
            tk: info.getAccessToken().getJwtToken(),
            sk: sk
          }
        };

        const p1 = new Promise((resolve, reject) => {
          API.del('CultureApi', '/notifications', init)
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error)
            });
        });

        Promise.all([p1])
          .then(values => {

            const p2 = new Promise((resolve, reject) => {
              API.post('CultureApi', '/notifications', init)
                .then(response => {
                  resolve(response);
                })
                .catch(error => {
                  reject(error)
                });
            });

            Promise.all([p2])
              .then(values => {

                this.props.setNotifications(values[0]);

                this.setState({
                  isError: false,
                  isLoading: false,
                  items: values[0]
                });
              })
              .catch(error => {
                console.log(error);
                this.setState({
                  isLoading: false,
                  isError: true
                });
              });
          })
          .catch(error => {
            console.log(error);
            this.setState({
              isLoading: false,
              isError: true
            });
          });
      });
  }

  render() {

    const { items, isLoading, userName } = this.state;

    let finalNotifications = []

    let { notificationState: { notification } } = this.props
    //let { app: { customizer } } = this.props

    if (notification.notifications.length > 0) {
      finalNotifications = notification.notifications;
    } else {
      finalNotifications = items;
    }

    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">

        <span className="text-bold-600 mt-2 mr-2 text-uppercase">Time to ListenUp!</span>
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <Icon.Bell size={21} />
            <Badge pill color="primary" className="badge-up">
              {" "}
              {finalNotifications.length}{" "}
            </Badge>
          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                <h3 className="text-white">{finalNotifications.length} New</h3>
                <span className="notification-title">App Notifications</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false
              }}
            >

              {isLoading &&
                <div className="text-center" >
                  <Spinner color="primary" className="m-1" />
                </div>
              }

              {finalNotifications.map(item => {

                const type = item.sk.split('#')[1];
                const days = item.dt === 0 ? 'Today' : item.dt === 1 ? 'Yesterday' : item.dt + ' Days'

                if (type === Constants.NOTIFICATION_SURVEY) {

                  return (
                    <div className="d-flex justify-content-between" onClick={() => this.handleNotificationClick(item.sk)} key={item.sk}>
                      <Media className="d-flex align-items-start" href="#">
                        <Media left>
                          <Icon.CheckSquare
                            className="font-medium-5 success"
                            size={21}
                          />
                        </Media>
                        <Media body>
                          <Media heading className="success media-heading" tag="h6">Survey In Progress</Media>
                          <p className="notification-text">{item.vl}</p>
                        </Media>
                        <small>
                          {days}
                        </small>
                      </Media>
                    </div>
                  )
                } else if (type === Constants.NOTIFICATION_ALERT) {
                  return (
                    <div className="d-flex justify-content-between" onClick={() => this.handleNotificationClick(item.sk)} key={item.sk}>
                      <Media className="d-flex align-items-start" href="#">
                        <Media left>
                          <Icon.AlertTriangle
                            className="font-medium-5 danger"
                            size={21}
                          />
                        </Media>
                        <Media body>
                          <Media heading className="danger media-heading" tag="h6">System Alert</Media>
                          <p className="notification-text">{item.vl}</p>
                        </Media>
                        <small>
                          {days}
                        </small>
                      </Media>
                    </div>
                  )
                } else if (type === Constants.NOTIFICATION_SCORE_ALERT) {
                  return (
                    <div className="d-flex justify-content-between" onClick={() => this.handleNotificationClick(item.sk)} key={item.sk}>
                      <Media className="d-flex align-items-start" href="#">
                        <Media left>
                          <Icon.AlertTriangle
                            className="font-medium-5 danger"
                            size={21}
                          />
                        </Media>
                        <Media body>
                          <Media heading className="danger media-heading" tag="h6">Score Alert</Media>
                          <p className="notification-text">{item.vl}</p>
                        </Media>
                        <small>
                          {days}
                        </small>
                      </Media>
                    </div>
                  )
                } else if (type === Constants.NOTIFICATION_MESSAGE) {
                  return (
                    <div className="d-flex justify-content-between" onClick={() => this.handleNotificationClick(item.sk)} key={item.sk}>
                      <Media className="d-flex align-items-start" href="#">
                        <Media left>
                          <Icon.MessageSquare
                            className="font-medium-5 success"
                            size={21}
                          />
                        </Media>
                        <Media body>
                          <Media heading className="success media-heading" tag="h6">System Alert</Media>
                          <p className="notification-text">{item.vl}</p>
                        </Media>
                        <small>
                          {days}
                        </small>
                      </Media>
                    </div>
                  )
                } else if (type === Constants.NOTIFICATION_REWARD_RECEIVED) {
                  return (
                    <div className="d-flex justify-content-between" onClick={() => this.handleNotificationClick(item.sk)} key={item.sk}>
                      <Media className="d-flex align-items-start" href="#">
                        <Media left>
                          <Icon.Award
                            className="font-medium-5 success"
                            size={21}
                          />
                        </Media>
                        <Media body>
                          <Media heading className="success media-heading" tag="h6">Reward Received</Media>
                          <p className="notification-text">{item.vl}</p>
                        </Media>
                        <small>
                          {days}
                        </small>
                      </Media>
                    </div>
                  )
                } else if (type === Constants.NOTIFICATION_REWARD_REDEEM) {
                  return (
                    <div className="d-flex justify-content-between" onClick={() => this.handleNotificationClick(item.sk)} key={item.sk}>
                      <Media className="d-flex align-items-start" href="#">
                        <Media left>
                          <Icon.Gift
                            className="font-medium-5 success"
                            size={21}
                          />
                        </Media>
                        <Media body>
                          <Media heading className="success media-heading" tag="h6">Received Redeem Amount</Media>
                          <p className="notification-text">{item.vl}</p>
                        </Media>
                        <small>
                          {days}
                        </small>
                      </Media>
                    </div>
                  )
                }else{
                  return (<div></div>)
                }

              })};

            </PerfectScrollbar>
            <li className="dropdown-menu-footer">
              <DropdownItem tag="a" className="p-1 text-center">
                <span className="align-middle">Close</span>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none mt-1 mr-2">
              <span className="user-name text-bold-600">
                {userName}
              </span>
            </div>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul >
    )
  }
}

const mapStateToProps = state => {
  return {
    notificationState: state.notification,
    app: state.customizer
  }
}

export default connect(mapStateToProps, {
  setNotifications,
  changeMode
})(NavbarUser)

