const cartReducer = (state = { cartItems: [] }, action) => {
  switch (action.type) {
    case "CARTITEMS": {
      return { ...state, cartItems: action.cartItems }
    }
    default: {
      return state
    }
  }
}

export default cartReducer
